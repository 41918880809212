import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const TwoPocketCounterSorter = ({ data }) => {
  return (
    <Layout>
      <SEO title="MIB-11 Two Pocket Mixed Note Value Counter And Sorter" />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="flex flex-wrap w-full pb-8 mx-auto mb-8 border-b-2 border-transparent max-w-7xl">
        <div className="flex items-center justify-center w-full md:w-1/2">
          <div className="w-full max-w-lg px-4 mx-auto">
            <h2 className="text-sm font-bold leading-none tracking-wide uppercase text-brand-blue">
              MIB-11 Two Pocket Mixed Note Value Counter And Sorter
            </h2>
            <h3 className="mb-6 text-3xl font-bold leading-none">
              Excellence that exceeds your expectations
            </h3>
            <p className="mb-4">
              The New MIB-11 counts mixed denomination notes at a rate of 1000
              notes/minute. The MIB-11 is ideal for general use in retail,
              gaming, cash in transit and banking. the unit will count, sort,
              batch, issue sort and face/orientate.
            </p>
            <p className="mb-4">
              The MIB-11 is the latest note counter released by Hyundai-MIB,
              featuring the most advanced cash-processing technology available,
              and is designed for high-performance in the most demanding
              environments. Featuring accurate Serial Number and TITO ticket
              scanning and recording, you no longer need to store TITO tickets
              physically. This unit can be networked to back-office applications
              and reconciliation systems.
            </p>
            <p className="mb-4">
              This machine is a note counting and sorting solution, resulting in
              significant time savings, and a quick return on investment. The
              MIB-11 has been designed for mid to high use processing with a
              large hopper and stacker size. For accurate processing of large
              amounts of notes, we recommend this machine for simple and quick
              value counting.
            </p>
            <p className="mb-4">
              The MIB-11 Note Counter is an exciting note counting and sorting
              solution for all markets, and is tailor-made for Australia.
            </p>

            <Accordion allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Specifications</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <table>
                    <tbody>
                      <tr>
                        <td>Interface</td>
                        <td>4.3” Colour touch display</td>
                      </tr>
                      <td>Counting Speed (per minute)</td>
                      <td>700 / 1000 / 1200 notes</td>
                      <tr>
                        <td>Hopper Capacity</td>
                        <td>Up to approx. 500 notes</td>
                      </tr>
                      <tr>
                        <td>Stacker Capacity</td>
                        <td>Up to approx. 300 notes</td>
                      </tr>
                      <tr>
                        <td>Reject Capacity</td>
                        <td>100 notes</td>
                      </tr>
                      <tr>
                        <td>Dimensions</td>
                        <td>287mm (W) x 348mm (D) x 340mm (H)</td>
                      </tr>
                      <tr>
                        <td>Weight</td>
                        <td>13kg</td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div className="w-full px-4 mt-8 md:pl-8 md:mt-0 md:w-1/3">
          <div className="w-full max-w-sm px-4 mx-auto">
            <Image fluid={data.TwoPocketCounterSorter.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

TwoPocketCounterSorter.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query TwoPocketCounterSorterQuery {
    TwoPocketCounterSorter: file(
      relativePath: { eq: "products/mib11/main.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default TwoPocketCounterSorter;
